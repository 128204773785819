import React, { useState } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';



export default function NavAccount() {
    const [error, setError] = useState("");
    const { logout } = useAuth();
    const navigate = useNavigate();

    async function handleLogout() {
        setError("");
        try {
            await logout();
            navigate("/login");
        }   catch {
            setError("Failed to log out");
        }
    }

    return (
        <>
            <NavDropdown.Item href="/dashboard">Dashboard</NavDropdown.Item>
            <NavDropdown.Item href="/update-profile">Update Profile</NavDropdown.Item>
            <NavDropdown.Item onClick={handleLogout} href="/login">Log Out</NavDropdown.Item>
        </>
    )
}
