import React from 'react';
import { Card } from 'react-bootstrap';

export default function AboutSection3() {
  return (
    <div style={{ justifyContent: 'center', alignItems: 'center', width: '100%' }}>
      <Card style={{ width: '100%', alignItems: 'center', backgroundColor: '#2cf09b' }}>
        <Card.Title style={{ marginTop: '50px' }}><h1>FAQ</h1></Card.Title>
        <Card.Text>kj klasdglk lkgf klk</Card.Text>
      </Card>
    </div>
  )
}
