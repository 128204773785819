import React from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';

export default function AboutSection2() {
  return (
    <div>
      <Row xs={1} md={2} className="g-2">
        <Col>
          <Card style={{ backgroundColor: '#2cf09b' }}>
            <Card.Title><h1>Ben Sailor - CEO</h1></Card.Title>
            <Card.Text>
              Gzdsgjkjfjlk aslj erjglk; j;lksdjg lkjdsf;lkgj l;skd ;ljh ;ldshj slkdjh lsjdhlkksjd;lkfg lkjsfd;hlksd g ;lkjdfg kjdlakgj lkadjg lkag lkka laj ad lkdskfh l;ksdkjfhlskdjh dskfjh lk 
            </Card.Text>
            <Button variant="primary" id="green-btn">READ MORE</Button>
          </Card>
        </Col>
        <Col>
          <Card style={{ backgroundColor: '#2cf09b' }}>
            <Card.Title><h1>Marco Fanucchi - CFO</h1></Card.Title>
            <Card.Text>
              Gzdsgjkjfjlk aslj erjglk; j;lksdjg lkjdsf;lkgj l;skd ;ljh ;ldshj slkdjh lsjdhlkksjd;lkfg lkjsfd;hlksd g ;lkjdfg kjdlakgj lkadjg lkag lkka laj ad lkdskfh l;ksdkjfhlskdjh dskfjh lk 
            </Card.Text>
            <Button variant="primary" id="green-btn">READ MORE</Button>
          </Card>
        </Col>
      </Row>
      </div>  )
}
