import React from 'react';

import HomeSection1 from '../sections/home/HomeSection1';
import HomeSection2 from '../sections/home/HomeSection2';
import HomeSection3 from '../sections/home/HomeSection3';
import HomeSection4 from '../sections/home/HomeSection4';
import HomeSection5 from '../sections/home/HomeSection5';

export default function Home() {

  return (
    <div>
      <HomeSection1 />
      <br />
      <HomeSection2 />
      <br />
      <HomeSection3 />
      <br />
      <HomeSection4 />
      <br />
      <HomeSection5 />
    </div>
  )
}
