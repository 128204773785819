import React from 'react'
import { Row, Col, Card } from 'react-bootstrap'
import styled from 'styled-components';

import * as HiIcons from 'react-icons/hi';
import * as VscIcons from 'react-icons/vsc';
import * as GoIcons from 'react-icons/go';

const Icon = styled(Card.Text)`
  font-size: 35px;
`;

export default function HomeSection2() {
  const lightBulb = (<HiIcons.HiOutlineLightBulb></HiIcons.HiOutlineLightBulb>);
  const gear = (<VscIcons.VscSettingsGear></VscIcons.VscSettingsGear>);
  const person = (<GoIcons.GoPerson></GoIcons.GoPerson>);
  return (
    <div style={{ margin: '50px 40px', backgroundColor: '#251f1f' }}>
      <Card style={{ backgroundColor: 'transparent', border: 'none' }}>
        <Card.Title style={{ color: 'white', margin: '20px 0' }}><h id="whiteText">New Patient Intake Solutions</h></Card.Title>
        <Card.Text style={{ color: 'white', margin: '20px 0' }}>
          <p id="whiteText">We provide a software and hardware package that, when used together, will fully automate the process of checking in new patients at a dispensary. Our hardware takes a scanned image of a new patient's med card and hands it to our software on your computer. It is then processed using state of the art Optical Character Recognition and input directly into your patient database.</p>
        </Card.Text>
      </Card>
      <br />
      <div>
        <Row xs={1} md={3} className="g-3">
          <Col id="leftCol">
            <Card style={{ backgroundColor: 'transparent' }}>
              <Card.Img src={require('../../../data/AshTrayAndJoints-min.jpg')} style={{ width: '100%', height: '70%', objectFit: 'cover' }} />
              <Card.ImgOverlay>
                <Card.Title style={{ backgroundColor: '#2cf09b', color: 'white', width: '50px', height: '50px', textAlign: 'center', margin: '40px auto', display: 'block', borderRadius: '50%' }}>
                  <Icon>
                    {lightBulb}
                  </Icon>
                </Card.Title>
                <Card.Text style={{ marginLeft: '30px' }}>
                  <h3>Efficient</h3>
                  <ul>
                    <li>reasons</li>
                    <li>Reason 2</li>
                    <li>Anotha one</li>
                    <li>Reason 4</li>
                  </ul>
                </Card.Text>
              </Card.ImgOverlay>
            </Card>
          </Col>
          <Col>
            <Card style={{ backgroundColor: '#2cf09b', height: '610px' }}>
              <Card.Title style={{ backgroundColor: '#251f1f', color: '#2cf09b', width: '50px', height: '50px', textAlign: 'center', margin: '40px auto', display: 'block', borderRadius: '50%' }}><Icon>
                {gear}
              </Icon></Card.Title>
              <Card.Text id="text">
                <h3>Fast</h3>
                <ul>
                  <li>Highly Scalable</li>
                  <li>Our services can handle thousands of users at once</li>
                  <li>Runs on google Firebase</li>
                  <li>Designed to aid businesses in time efficiency of sales</li>
                </ul>
              </Card.Text>
            </Card>
          </Col>
          <Col id="rightCol">
            <Card style={{ backgroundColor: 'transparent' }}>
              <Card.Img src={require('../../../data/GirlSmoking-min.jpg')} style={{ width: '100%', height: '70%', objectFit: 'cover' }} />
              <Card.ImgOverlay>
                <Card.Title style={{ backgroundColor: '#2cf09b', color: 'white', width: '50px', height: '50px', textAlign: 'center', margin: '40px auto', display: 'block', borderRadius: '50%' }}>
                  <Icon>
                    {person}
                  </Icon>
                </Card.Title>
                <Card.Text id="text">
                  <h3>Secure</h3>
                  <ul>
                    <li>jk</li>
                    <li>Reason 2</li>
                    <li>Anotha one</li>
                    <li>Reason 4</li>
                  </ul>
                </Card.Text>
              </Card.ImgOverlay>
            </Card>
          </Col>
        </Row>
      </div>
    </div>  
  )
}
