import React from 'react';
import { Col, Row, Card, Form, Button } from 'react-bootstrap';

import * as HiIcons from 'react-icons/hi';
import * as MdIcons from 'react-icons/md';

export default function Contact() {
  const phone = (<HiIcons.HiPhone></HiIcons.HiPhone>);
  const email = (<MdIcons.MdEmail></MdIcons.MdEmail>);
  const clock = (<HiIcons.HiClock></HiIcons.HiClock>);

  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <div>
        <Row sm={1} md={2} className="g-2">
          <Col>
            <Card style={{ alignItems: 'left', margin: '50px', backgroundColor: 'transparent', color: 'white', border: 'none' }}>
              <Card.Title><h1>Contact Us</h1></Card.Title>
              <Card.Body>
                <h4>{phone} Call Us</h4>
                <p>+1 (832) 691-0027</p><br/>
                <h4>{email} Email Us</h4>
                <p>Sales Department: sales@med-scanner.com</p>
                <p>Customer Support: support@med-scanner.com</p>
                <p>General Inquiries: info@med-scanner.com</p><br/>
                <h4>{clock} Business Hours</h4>
                <p>Monday - Friday: 9:00 AM - 5:00 PM</p> 
                <br/>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Form style={{ position: 'block' }}>
              <Form.Group className="mb-3" controlId="formBasicEmail" style={{ width: '40%' }}>
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" placeholder="Enter email" />
                <Form.Text className="text-muted">
                  We'll never share your email with anyone else.
                </Form.Text>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword" style={{ width: '40%' }}>
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" placeholder="Password" />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Check type="checkbox" label="Check me out" />
              </Form.Group>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  )
}
