import React, { useState } from 'react';
import { Nav, NavDropdown } from 'react-bootstrap';
import styled from 'styled-components';
import * as RiIcons from 'react-icons/ri';
import { useAuth } from '../../contexts/AuthContext';
import NavAccount from './NavAccount';
import NavLogin from './NavLogin';

const NavIcon = styled(NavDropdown)`
    font-size: 30px;
`;

export default function NavRight() {
    const accountTitle = (<RiIcons.RiAccountCircleLine></RiIcons.RiAccountCircleLine>);
    const { currentUser } = useAuth();
    const [logged, setLogged] = useState(false);

    function isLogged() {
        if (!currentUser) {
            setLogged(false);
        }   else {
            setLogged(true);
        }
    }

    return (
        <>
            <Nav>
                <NavIcon title={accountTitle} onClick={isLogged} id="collapsible-nav-dropdown" bg="dark" variant="darj" href="#subscriptions">
                    {logged ? <NavAccount /> : <NavLogin />}
                </NavIcon>
            </Nav>
        </>
    )
}
