import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavBar from './nav/NavBar';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Login from './pages/Login';
import Signup from './pages/Signup';
import UpdateProfile from './pages/UpdateProfile';
import ForgotPassword from './pages/ForgotPassword';
import Dashboard from './pages/Dashboard';
import ErrorPage from './pages/ErrorPage';
import { AuthProvider } from '../contexts/AuthContext';


export default function App() {
  return (
    <div className="App">
      <AuthProvider>
        <Router>
          <NavBar />
          <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: "100vh" }} id='page'>
            <Routes>
              <Route exact path="/" element={<Home />} />
              {/* <Route path="/products" element={<Products />} />
              <Route path="/pricing" element={<Pricing />} /> */}
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path='/UpdateProfile' element={<UpdateProfile />} />
              <Route path='/forgot-password' element={<ForgotPassword />} />
              <Route path='/dashboard' element={<Dashboard />} />
              <Route path='*' element={<ErrorPage />} />
            </Routes>
          </Container>
        </Router>
      </AuthProvider>
    </div>
  );
}