import React from 'react'
import { Card } from 'react-bootstrap'

export default function HomeSection5() {
  return (
    <div style={{ margin: '0', width: '100%' }}>
        <Card style={{ backgroundColor: 'Transparent', border: 'none', color: '#2cf09b', width: '100%' }}>
            <Card.Img src={require('../../../data/DarkWeed1.jpg')} style={{ width: '100%', height: '20%', objectFit: 'cover' }} />
            <Card.ImgOverlay>
                <Card.Title style={{ backgroundColor: 'transparent', background: 'none' }}>
                  <h1 style={{ backgroundColor: 'transparent', background: 'none' }}>
                    TExt
                  </h1>
                </Card.Title>
            </Card.ImgOverlay>
        </Card>
    </div>  
  )
}