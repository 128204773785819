import React from 'react'
import { Row, Col, Card, Button } from 'react-bootstrap';


export default function HomeSection3() {
  return (
    <div style={{ backgroundColor: '#2cf09b', width: '99vw', height: '150px', margin: '50px 0 0' }}>
      <Row xs={1} md={2} className="g-2">
        <Col>
          <Card style={{ backgroundColor: 'transparent', border: 'none', width: '100%', margin: '40px 0' }}>
            <Card.Title style={{ margin: '0 0 0 20px'}}>
              <h4>
                Providing dispensaries a modern technology to increase the rate at which they can process new patients.
              </h4>
            </Card.Title>
          </Card>
        </Col>
        <Col>
            <Card style={{ backgroundColor: 'transparent', border: 'none', width: '30%', margin: '20px auto', alignContent: 'center', justifyContent: 'center' }}>
              <Button variant="primary" href="tel:+1 832 691 0027" style={{ margin: '20px auto auto', backgroundColor: '#251f1f', color: 'white', borderRadius: '40px' }}>
                +1 (832) 691-0027
              </Button>
            </Card>
          </Col>
        </Row>
      </div>  )
}
