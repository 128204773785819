import React from 'react'
import { Row, Col, Card } from 'react-bootstrap';

import * as HiIcons from 'react-icons/hi';
import * as VscIcons from 'react-icons/vsc';
import * as GoIcons from 'react-icons/go';
import * as BsIcons from 'react-icons/bs';

export default function HomeSection4() {
  const gear = (<VscIcons.VscSettingsGear></VscIcons.VscSettingsGear>);
  const lightBulb = (<HiIcons.HiOutlineLightBulb></HiIcons.HiOutlineLightBulb>);
  const people = (<BsIcons.BsPeople></BsIcons.BsPeople>);
  const phoneVibrate = (<BsIcons.BsPhoneVibrate></BsIcons.BsPhoneVibrate>);
  const location = (<GoIcons.GoLocation></GoIcons.GoLocation>)
  const clipBoard = (<HiIcons.HiOutlineClipboard></HiIcons.HiOutlineClipboard>)


  return (
    <div style={{ backgroundColor: 'transparent', height: '100%', margin: '50px' }}>
      <Row xs={1} md={3} className="g-3">
        <Col>
        <Card style={{ backgroundColor: 'transparent', border: 'none', height: '150px', margin: '50px 0', color: 'white', alignItems: 'center', justifyContent: 'center' }}>
            <Card.Title style={{ backgroundColor: '#2cf09b', color: 'white', width: '50px', height: '50px', textAlign: 'center', margin: '40px', display: 'block', borderRadius: '50%', fontSize: '30px' }}>
              {lightBulb}
            </Card.Title>
            <Card.Text>
              <h2>Machine Learning Solutions</h2>
              <h4>Our systems boast an accuracy of 98% and due to its machine learning nature this will constantly improve.</h4>
            </Card.Text>
          </Card>
        </Col> 
        <Col>
          <Card style={{ backgroundColor: 'transparent', border: 'none', height: '150px', margin: '50px 0', color: 'white', alignItems: 'center', justifyContent: 'center' }}>
            <Card.Title style={{ backgroundColor: '#2cf09b', color: 'white', width: '50px', height: '50px', textAlign: 'center', margin: '40px', display: 'block', borderRadius: '50%', fontSize: '30px' }}>
              {gear}
            </Card.Title>
            <Card.Text>
              <h2>HIPA Compliant</h2>
              <h5>We do not store any patient data. The only data that we keep is some individual characters in order to train our systems to work better in the future.</h5>
            </Card.Text>
          </Card>
        </Col>
        <Col>
        <Card style={{ backgroundColor: 'transparent', border: 'none', height: '150px', margin: '50px 0', color: 'white', alignItems: 'center', justifyContent: 'center' }}>
            <Card.Title style={{ backgroundColor: '#2cf09b', color: 'white', width: '50px', height: '50px', textAlign: 'center', margin: '40px', display: 'block', borderRadius: '50%', fontSize: '30px' }}>
              {phoneVibrate}
            </Card.Title>              
            <Card.Text>
              <h2>Highly Secure</h2>
              <h5>We employ all cutting edge frameworks to make this service work properly together. Using google firebase servers, authentication, and security, we can ensure a fully secure product</h5>
            </Card.Text>
          </Card>
        </Col>
      </Row>
      <Row xs={1} md={3} className="g-3">
        <Col>
        <Card style={{ backgroundColor: 'transparent', border: 'none', height: '150px', margin: '50px 0', color: 'white', alignItems: 'center', justifyContent: 'center' }}>
            <Card.Title style={{ backgroundColor: '#2cf09b', color: 'white', width: '50px', height: '50px', textAlign: 'center', margin: '40px', display: 'block', borderRadius: '50%', fontSize: '30px' }}>
              {clipBoard}
            </Card.Title>              
            <Card.Text>
              <h2>Competitive Pricing</h2>
              <h5>Our service starts at $50/month or $500/year for to automate the login process</h5>
            </Card.Text>
          </Card>
        </Col>
        <Col>
        <Card style={{ backgroundColor: 'transparent', border: 'none', height: '150px', margin: '50px 0', color: 'white', alignItems: 'center', justifyContent: 'center' }}>
            <Card.Title style={{ backgroundColor: '#2cf09b', color: 'white', width: '50px', height: '50px', textAlign: 'center', margin: '40px', display: 'block', borderRadius: '50%', fontSize: '30px' }}>
              {location}
            </Card.Title>              
            <Card.Text>
              <h2>Innovative</h2>
              <h5>Our cutting edge implementation of optical character recognition software makes this all possible</h5>
            </Card.Text>
          </Card>
        </Col>
        <Col>
        <Card style={{ backgroundColor: 'transparent', border: 'none', height: '150px', margin: '50px 0', color: 'white', alignItems: 'center', justifyContent: 'center' }}>
            <Card.Title style={{ backgroundColor: '#2cf09b', color: 'white', width: '50px', height: '50px', textAlign: 'center', margin: '40px', display: 'block', borderRadius: '50%', fontSize: '30px' }}>
              {people}
            </Card.Title>
            <Card.Text>
              <h2>Fast Support</h2>
              <h5>Call us any day Monday - Friday 9:00 a.m. - 8:00 p.m. to speak with one of our friendly and knowledgeable customer support agents</h5>
            </Card.Text>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
