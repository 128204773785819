import React from 'react';
import { NavDropdown } from 'react-bootstrap';


export default function NavLogin() {
    return (
        <>
            <NavDropdown.Item href="/login">Log in</NavDropdown.Item>
            <NavDropdown.Item href="/signup">Sign Up</NavDropdown.Item>
        </>
    )
}
