import React from 'react';

import AboutSection1 from '../sections/about/AboutSection1';
import AboutSection2 from '../sections/about/AboutSection2';
import AboutSection3 from '../sections/about/AboutSection3';

export default function About() {
  return (
    <div id="about">
      <AboutSection1 />
      <br />
      <AboutSection2 />
      <br />
      <AboutSection3 />
    </div>
  )
}
