import React from 'react';
import { Card } from 'react-bootstrap';

export default function AboutSection1() {
  return (
    <div style={{ height: '200px', margin: '40px 0' }}>
      <Card style={{ width: '100%', color: 'white', backgroundColor: 'transparent', border: 'none' }}>
        <Card.Title><h1>Here at MedScanner LLC We...</h1></Card.Title>
        <Card.Text>
          important text and stuff askjdfn;kasjdflk jsaflk jlsdkfj alskjf lksdjf ;lksajd lksjd lgkjdaflkg jdlfkjg dlkfgj lkdfjg ;lsdkjh lksdfjh lkdjfhl kjsdfh'lk jdfhlk jdsflkkhj dlkfj
        </Card.Text>
      </Card>
    </div> 
  )
}
