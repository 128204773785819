import React, { useContext, useState, useEffect } from 'react'
import { auth, db } from '../data/Firebase'

const AuthContext = React.createContext()

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState();
    const [loading, setLoading] = useState(true);

    // function signup(email, password) {
    //     return auth.createUserWithEmailAndPassword(email, password);
    // }

    async function signup(email, password) {
        try {
            const userCredentials = await auth.createUserWithEmailAndPassword(email, password);
            //await db.collection('AccountData').doc(userCredentials.user.uid).set({ email });
            await userCredentials.user.sendEmailVerification();
            return userCredentials;
        }   catch(error) {
            return error;
        }

    }

    function login(email, password) {
        return auth.signInWithEmailAndPassword(email, password);       
    }

    function logout() {
        return auth.signOut();
    }

    function resetPassword(email) {
        return auth.sendPasswordResetEmail(email);
    }

    function updatePassword(email, password) {
        return currentUser.updatePassword(password);
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => { 
            setCurrentUser(user);
            setLoading(false);
        })
        return unsubscribe
    }, [])

    const value = { 
        currentUser, 
        login,
        signup,
        logout,
        resetPassword,
        updatePassword
    }
    
    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}