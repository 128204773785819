import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from '../../data/logo.svg';
import NavRight from './NavRight';

export default function NavBar() {
  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="Light" variant="Light" sticky="top" id="nav">
        <Container>
          <Navbar.Brand href="/">
            <img
              alt=""
              src={logo}
              width="50"
              height="50"
              className="d-inline-block align-middle"
            />{' '}
            MedScanner
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <NavDropdown title="Pricing" id="collasible-nav-dropdown" href="#subscriptions">
                <NavDropdown.Item href="#action/3.4">Subscriptions</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#subscriptions/green">Green Tier</NavDropdown.Item>
                <NavDropdown.Item href="#subscriptions/green">Purple Tier</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="#Security">Security</Nav.Link>
              <Nav.Link href="/about">About</Nav.Link>
              <Nav.Link href="/contact">Contact Us</Nav.Link>
            </Nav>
            <NavRight />
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}