import React from 'react'
import { Row, Col, Card, Button } from 'react-bootstrap'

export default function HomeSection1() {
  return (
    <div style={{ margin: '20px'}}>
      <Row xs={1} md={2} className="g-2">
        <Col>
            <Card style={{ margin: '100px 0 50px 30px', border: 'none', backgroundColor: '#251f1f', color: '#2cf09b' }}>
                <Card.Title><h1>MedScanner LLC</h1></Card.Title>
                <Card.Text>
                  <h3>Here at MedScanner LLC, we are devoted to ensuring patients get their product as fast as possible. By employing our specialized optical character recognition system, businesses of any size can speed up their patient intake process by over 10x</h3>
                </Card.Text>
                <Button variant="primary" style={{ backgroundColor: '#2cf09b', color: '#251f1f', fontWeight: 'bold', cursor: 'pointer', width: '120px', fontSize: '15px', marginLeft: '10px', borderRadius: '25px' }}>READ MORE</Button>
            </Card>
        </Col>
        <Col>
          <Card style={{ margin: '30px 0', height: '400px', border: 'none', backgroundColor: '#251f1f' }}>
            <Card.Img src="../../data/logo.svg"></Card.Img>
          </Card>
        </Col>
      </Row>
    </div>  
  )
}